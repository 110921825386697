import React from 'react';
import { WrenchIcon, HandshakeIcon, BoltIcon } from 'lucide-react';

const ValueProposal_items = [
  {
    img: "images/assets/asset 12.svg",
    title: "Proximité et disponibilité",
    description: "Située au nord du Finistère, l'atelier est accessible pour tous types d'engins. Nous intervenons également dans un rayon inférieur à 60 kms de Plabennec."
  },
  {
    img: "images/assets/asset 13.svg",
    title: "Projet sur mesure",
    description: "Grâce à nos compétences techniques, nous vous accompagnons tout au long de votre projet. De l'étude à la livraison des pièces."
  },
  {
    img: "images/assets/asset 14.svg",
    title: "Une réactivité à toute épreuve",
    description: "KDERRA propose un service de découpage et de soudure réactif afin de répondre à une commande urgente de nos clients."
  },

];

const ValueCard = ({ icon, title, description }) => (
  <div className="flex flex-col items-center space-y-4 text-center">
    {React.isValidElement(icon) ? (
      icon
    ) : (
      <img src={icon} alt={title} className="h-16 w-16 object-contain" />
    )}
    <h3 className="text-xl font-bold">{title}</h3>
    <p className="text-muted-foreground">{description}</p>
  </div>
);

export default function ValuesSection() {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-muted">
      <div className="container px-4 md:px-6">
        <div className="space-y-4 text-center">
          <div className="inline-block rounded-lg bg-muted-foreground px-3 py-1 text-sm text-muted">
            Nos Valeurs
          </div>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
            Les Valeurs de Kderra soudure
          </h2>
          <p className="mx-auto max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Nous sommes guidés par des valeurs
            fortes qui guident notre action au quotidien.
          </p>
        </div>
        <div className="mx-auto grid max-w-7xl gap-8 py-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          <ValueCard
            icon={<WrenchIcon className="h-16 w-16 text-primary" />}
            title="Expertise"
            description="Nos équipes sont composées d'experts passionnés, formés aux dernières techniques de soudure, pliage et découpe."
          />
          <ValueCard
            icon={<HandshakeIcon className="h-16 w-16 text-primary" />}
            title="Service Client"
            description="Nous mettons un point d'honneur à offrir un service client de qualité et à répondre à tous vos besoins."
          />
          <ValueCard
            icon={<BoltIcon className="h-16 w-16 text-primary" />}
            title="Innovation"
            description="Nous investissons dans les dernières technologies pour vous proposer des solutions toujours plus performantes."
          />
          {ValueProposal_items.map((item, index) => (
            <ValueCard
              key={index}
              icon={item.img}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}