import { Link } from "react-router-dom";
import ContactSection from "./home-contact-section";
import Hero from "./Hero";
import PartnersSection from "./partnairs-section";
import ValuesSection from "./value-section";


export default function Home2() {
  return (
    <div className="flex flex-col py-12 overflow-x-hidden ">
      <main className="flex-1 bg-background">
        <section className="w-full py-12 md:py-24 lg:py-16">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4">
                <div className="space-y-2 text-left">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                    Kderra Soudure : L'expertise au service de vos projets
                  </h1>
                  <p className="max-w-[600px] text-muted-foreground md:text-xl ">
                  Kderra est une entreprise basé à Saint-fiacre spécialisée dans la
            soudure générale, la fabrication de structure d'acier, inox et alu,
            la réparation agricole et industriel et la restauration de pièce
            mécanique. Nous disposons d'un espace de stockage de ... m2 et nous
            sommes outillés en scie et plieuse et avons un lot de matériels
            conventionnels pour fabriquer nos ensembles chaudronnés.
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row">
                  <Link
                    to={"/realisations"}
                    className="inline-flex h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Nos Réalisations
                  </Link>
                  <Link
                    to={"/contact"}
                    className="inline-flex h-10 items-center justify-center rounded-md border border-input bg-background px-8 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                    prefetch={false}
                  >
                    Contactez-nous
                  </Link>
                </div>
              </div>

              <div className="relative w-full h-full min-h-[300px] lg:min-h-[400px] overflow-hidden rounded-xl shadow-lg">
                {/* Image améliorée pour petits écrans */}
                <div className="relative w-full h-full lg:hidden">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/kderra_img/soudure_2.jpg"
                    }
                    alt="soudure"
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
                    <div className="absolute bottom-4 left-4 right-4 text-white">
                      <h2 className="text-2xl font-bold mb-2">
                        Expertise en Soudure
                      </h2>
                      <p className="text-sm">
                        Qualité et précision dans chaque projet
                      </p>
                    </div>
                  </div>
                </div>

                {/* Hero pour grands écrans */}
                <div className="hidden lg:block w-full h-full">
                  <Hero />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-12 welding-background text-white">
          <div className="container px-4 md:px-6">
            <div className="space-y-2 text-center">
              <div className="inline-block rounded-lg text-black bg-muted px-3 py-1 text-sm">
                Nos Domaines d'Expertise
              </div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                Soudure, Pliage et Découpe
              </h2>
              <p className="mx-auto max-w-[900px]  md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Chez Kderra Soudure, nous maîtrisons une large gamme de
                techniques de soudure, de pliage et de découpe pour répondre à
                tous vos besoins. Découvrez nos principaux domaines
                d'intervention.
              </p>
            </div>
            <div className="mx-auto grid max-w-5xl gap-12 py-12 lg:grid-cols-3 text-center">
              <div className="grid gap-4 justify-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/images/masque-de-soudure.png"}
                  className="h-12 w-12 text-primary"
                  alt="masque-de-soudure"
                />
                <h3 className="text-xl font-bold">Soudure</h3>
                <p className="">
                  Nos équipes maîtrisent les techniques de soudure MIG, TIG et à
                  l'arc pour des réalisations sur-mesure.
                </p>
              </div>
              <div className="grid gap-4 justify-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/images/metal.png"}
                  className="h-12 w-12 text-primary"
                  alt="metal"
                />
                <h3 className="text-xl font-bold">Pliage</h3>
                <p className="">
                  Nous réalisons des pliages de précision sur une large gamme de
                  matériaux pour vos projets.
                </p>
              </div>
              <div className="grid gap-4 justify-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/images/decoupe-au-laser.png"}
                  className="h-12 w-12 text-primary"
                  alt="decoupe-au-laser"
                />
                <h3 className="text-xl font-bold">Découpe</h3>
                <p className="">
                  Nos équipes maîtrisent les techniques de découpe laser, plasma
                  et oxycoupage pour des réalisations sur-mesure.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-12">
          <div className="container px-4 md:px-6">
            <div className="space-y-4 text-center">
              <div className="inline-block rounded-lg bg-muted px-3 py-1 text-sm">
                Nos Services
              </div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                Nos Domaines d'Intervention
              </h2>
              <p className="mx-auto max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Nous offrons une large gamme de services pour répondre à tous
                vos besoins.
              </p>
            </div>
            <div className="mx-auto grid max-w-5xl gap-12 py-12 lg:grid-cols-3 text-center">
              <div className="grid gap-4 justify-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/images/reparation.png"}
                  className="h-12 w-12 text-primary"
                  alt="reparation"
                />
                <h3 className="text-xl font-bold">Réparation Agricole</h3>
                <p className="text-muted-foreground">
                  Nous offrons des services de réparation et de maintenance pour
                  vos équipements agricoles.
                </p>
              </div>
              <div className="grid gap-4 justify-items-center">
                <CogIcon className="h-12 w-12 text-primary" />
                <h3 className="text-xl font-bold">Réparation Industrielle</h3>
                <p className="text-muted-foreground">
                  Nous réalisons des réparations et de la maintenance pour vos
                  équipements industriels.
                </p>
              </div>
              <div className="grid gap-4 justify-items-center">
                <CarIcon className="h-12 w-12 text-primary" />
                <h3 className="text-xl font-bold">Réparation Mécanique</h3>
                <p className="text-muted-foreground">
                  Nos experts effectuent des réparations et de la maintenance
                  pour vos véhicules et équipements mécaniques.
                </p>
              </div>
            </div>
          </div>
        </section>

        <ValuesSection />

        <PartnersSection />
        <ContactSection />
      </main>
    </div>
  );
}


function CarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2" />
      <circle cx="7" cy="17" r="2" />
      <path d="M9 17h6" />
      <circle cx="17" cy="17" r="2" />
    </svg>
  );
}

function CogIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
      <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
      <path d="M12 2v2" />
      <path d="M12 22v-2" />
      <path d="m17 20.66-1-1.73" />
      <path d="M11 10.27 7 3.34" />
      <path d="m20.66 17-1.73-1" />
      <path d="m3.34 7 1.73 1" />
      <path d="M14 12h8" />
      <path d="M2 12h2" />
      <path d="m20.66 7-1.73 1" />
      <path d="m3.34 17 1.73-1" />
      <path d="m17 3.34-1 1.73" />
      <path d="m11 13.73-4 6.93" />
    </svg>
  );
}
