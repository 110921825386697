 
export const MenuItems = [
    {
        title : "Accueil",
        url : "/",
        cName : "nav-links",
        icon : "fa-solid fa-house-user",
        id : "accueil"

    },
    {
        title : "Services",
        url : "/services",
        cName : "nav-links",
        icon : "fa-solid fa-people-arrows",
        id : "services"

    },
    {
        title : "Réalisations",
        url : "/realisations",
        cName : "nav-links",
        icon : "fa-solid fa-window-restore",
        id : "realisation"

    },
    {
        title : "À propos",
        url : "/appropos",
        cName : "nav-links",
        icon : "fa-solid fa-address-card",
        id:"appropos"
    },
    {
        title : "Contacter",
        url : "/contacter",
        cName : "nav-links-contact",
        icon : "fa-solid fa-phone",
        id : "contacter"

    }
]


// carousel MenuItems, need some changes
export const Hero_carousel = [
    {
        title : "Soudure",
        description : "Assemblage de pièces en acier, inox, aluminium, par la Soudure à l'arc.",
        image : "/images/kderra_img/soudure_2.jpg"
    }, 
    {
        title : "Découpe",
        description : "Découpe de métal sur mesure : acier, inox, aluminium.",
        image : "/images/kderra_img/decoupe.jpg"
    },
    {
        title : "Pliage",
        description : "Pliage de métal, tôle, panneaux, inox, matériaux composites",
        image : "/images/kderra_img/pliage_net_2.webp"
    }
    



]


// technologies items 
export const featured = [
    {
      cover: "images/featured/h1.png",
      name: "Data Science",
      
    },
    {
      cover: "images/featured/h2.png",
      name: "IA",
      
    },
    {
      cover: "images/featured/h3.png",
      name: "Desktop",
      
    },
    {
      cover: "images/featured/h4.png",
      name: "Mobile",
      
    },
    {
      cover: "images/featured/h6.png",
      name: "Web",
      
    },
    {
      cover: "images/featured/h4.png",
      name: "IOT",
      
    },
    {
        cover: "images/featured/h4.png",
        name: "IOT",
        
      },
      {
        cover: "images/featured/h4.png",
        name: "IOT",
        
      },
  ]


//   some projects and theirs descriptions for home page, projects sections 
export const Project_items = [
    {
        id: 1,
        name: "RÉPARATION AGRICOLE",
        description : "Modification, fabrication et réparation de pièces agricoles(lames des godets, cornadis,fabrication de rabots, tonne a eau, auge, piquets de clôture, des patins de rabots, de barrières de silo, ...)",
        cover: "/images/kderra_img/visuel reparation agricole.JPG",
      },
      {
        id: 2,
        name: "RÉPARATION industrielle",
        entreprise: "ToemeXpertise",
        description : "Assemblage, découpe, pliage de pièces industrielles.Réparation et dépannage de canalisations, conduits tubulaires, skids, échangeurs, pour le transport de fluide, matière, gaz, ...",
        cover: "/images/kderra_img/visuel reparation industrielle.jpg",
      },
      {
        id: 3,
        name: "RÉPARATION mécanique",
        entreprise: "ToemeXpertise",
        description : "Achat et vente de voitures d'occasion. Ressouder, redresser ou découper vos pièces mécaniques endommagées ou usées (échappement, tôle, mécanique, ...)",
        cover: "/images/kderra_img/reparation_mecanique.jpg",
      },
]



// footer items
export const community = [
    {
        name:"Github",

    },
    {
        name:"Issues",

    },
    {
        name:"Project",

    },
    {
        name:"Twitter",

    },
    {
        name:"Discord",

    },

]


export const help=  [
    {
        name:"Support",

    },
    {
        name:"Troubleshooting",

    },
    {
        name:"Contact Us",

    },
   

]

export const others=[
    {
        name:"Terms of Service",

    },
    {
        name:"Privacy",

    },
    {
        name:"policy",

    },
    {
        name:"License",

    },
]


export const ValueProposal_items = [
    {
        img: "images/assets/asset 12.svg",
        title :"proximité et disponibilté",
        description:"Située au nord du Finistère, l'atelier est accessible pour tous types d'engins. Nous intervenons également dans un rayon inférieur à 60 kms de Plabennec."
    },
    {
        img: "images/assets/asset 13.svg",
        title :"Projet sur mesure",
        description:"Grâce à nos compétences techniques, nous vous accompagnons tout au long de votre projet. De l'étude à la livraison des pièces."
    },
    {
        img: "images/assets/asset 14.svg",
        title :"Une réactivité à toute epreuve",
        description:"KDERRA propose un service de découpage et de soudure réactif afin de répondre à une commande urgente de nos clients."
    },
    {
        img: "images/assets/asset 15.svg",
        title :"Stock de matières premières",
        description:"Le stock de matières premières en métaux et visseries permet à l'entreprise d'assurer tous vos projets."
    }
]

export const Tools_items = [
    {
        img:"images/tools/asset 15.jpeg",
        title:"Perceuse a colonne"
    },
    {
        img:"images/tools/asset 9.jpeg",
        title:"Scie à ruban auto"
    },
    {
        img:"images/tools/asset 9.jpeg",
        title:"Cisaille poissonneuse"
    },
    {
        img:"images/tools/asset 10.jpeg",
        title:"Presse plieuse"
    },
    {
        img:"images/tools/asset 11.jpeg",
        title:"Poinçonneuse"
    },
    {
        img:"images/tools/asset 12.jpeg",
        title:"Rouleuse"
    },
    {
        img:"images/tools/asset 13.jpeg",
        title:"Poste semi automatique"
    },
    {
        img:"images/tools/asset 14.jpeg",
        title:"Fraiseuse"
    },
    {
        img:"images/tools/asset 16.jpeg",
        title:"Guillotine 3 x 10"
    },
]

export const Clients = [
    {
        img:"images/assets/asset 16.jpeg"
    },
    {
        img:"images/assets/asset 17.jpeg"
    },
]




export const realisation=[
    {
        id: "groupe1",
        groupe1: {
            "images": [
                "images/kderra_img/realisations/Groupe1/Groupe1.png",
                "images/kderra_img/realisations/Groupe1/Groupe1 2.png",
                "images/kderra_img/realisations/Groupe1/Groupe1 3.png"
            ]
        },
        text:"loremm"
    },
    {
        id: "Charue",
        Charue: {
            images: [ 
                "images/kderra_img/realisations/Charue/Charrue2.png",
                "images/kderra_img/realisations/Charue/Charrue3.png",
                "images/kderra_img/realisations/Charue/Charrue1.png",
                "images/kderra_img/realisations/Charue/Charrue.png",
            ]
        },
        text:"loremm"
    },
    {
        id: "Cuves",
        Cuves: {
            images: [
                "images/kderra_img/realisations/Cuves/Cuve de 3000L.png",
                "images/kderra_img/realisations/Cuves/Cuve1 de 3000L.png",
                "images/kderra_img/realisations/Cuves/Cuve2 de 3000L.png"
            ]
        },
        text:"loremm"
    },
    {
        id: "groupe2",
        groupe2: {
            images: [
                "images/kderra_img/realisations/Groupe2/Groupe6.png",
                "images/kderra_img/realisations/Groupe2/Groupe6 1.png",
            ]
        },
        text:"loremm"
    },
    {
        id: "groupe3",
        groupe3: {
            images: [
                "images/kderra_img/realisations/Groupe3/Groupe7 1.png",
                "images/kderra_img/realisations/Groupe3/Groupe7 2.png",
                "images/kderra_img/realisations/Groupe3/Groupe 7.png"
            ]
        },
        text:"loremm"
    },
    {
        id: "groupe4",
        groupe4: {
            images: [
                "images/kderra_img/realisations/Groupe4/Groupe12.png",
            ]
        },
        text:"loremm"
    },
    {
        id: "groupe5",
        groupe5: {
            images: [
                "images/kderra_img/realisations/Groupe5/Groupe5.png",
            ]
        },
        text:"loremm"
    },
    
    ]