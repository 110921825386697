import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import "../Styles/AtomeHero.css";

function AtomeHero(props) {
  const hasMultiText = Array.isArray(props.multiText) && props.multiText.length > 0;
  const hasTitle = typeof props.title === 'string';

  // First typewriter effect for the title or any primary text
  const [titleText] = useTypewriter({
    words: hasTitle ? [props.title] : [],

    typeSpeed: 100,
    delaySpeed: 1000,
    textStyle:{
      fontSize: "1.3rem",
      fontWeight: 800,
      WebkitBackgroundClip: "text",
      
    }
  });

  // Second typewriter effect for the multiText array
  const [multiText] = useTypewriter({
    words: hasMultiText ? props.multiText : [],
    loop: true,
    typeSpeed: 50,
    deleteSpeed: 30,
    delaySpeed: 1500,
  });

  return (
    <div className='AtomeHero'>
      <div className={props.cName}>
        <img src={process.env.PUBLIC_URL + props.heroImg} alt="Hero" />
        <div className="hero-txt">
          {hasTitle && (
            <h1 className="">
              {titleText}
              <Cursor />
            </h1>
          )}

          {hasMultiText && (
            <h2 className="text-white text-2xl font-semibold mt-4">
              {multiText}
              <Cursor />
            </h2>
          )}

          {props.text && <p className="text-white text-lg mt-4">{props.text}</p>}

          {props.url && props.buttonText && (
            <a href={props.url} className={props.btnClass}>
              {props.buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default AtomeHero;
