export const projects_items = [
  {
    title: "🌾 Découvrez la Charrue Grégoire : L'Innovation au Service de l'Agriculture 🚜",
    description: `Découvrez la charrue Grégoire, spécialement réalisée pour l'un de nos clients, 
    alliant innovation et savoir-faire artisanal. Conçue pour répondre aux besoins spécifiques de 
    son exploitation, cette charrue se distingue par sa robustesse, sa précision, et sa capacité à 
    optimiser le labourage des sols. Grâce à l'utilisation de matériaux de haute qualité et à une 
    conception sur mesure, elle promet fiabilité et durabilité, même dans les conditions les plus 
    exigeantes. La satisfaction de notre client est notre plus grande fierté, et cette charrue incarne 
    notre engagement à offrir des solutions agricoles personnalisées et performantes.`,
    image1: "images/kderra_img/realisations/Charue/Charrue2.png",
    image2: "images/kderra_img/realisations/Charue/Charue3.png",
  },
  {
    title: "🚀 NOUVELLE RÉALISATION : CUVE SUR MESURE 🚀",
    description:
      "Nous sommes ravis de vous présenter notre dernière réalisation : une cuve fabriquée sur mesure, conçue avec précision et expertise pour répondre à des besoins spécifiques ! 🛠️🔧.Une cuve sur mesure est une solution efficace pour répondre à vos besoins spécifiques, tout en offrant un confort élevé et une durabilité maximale.",
    image1: "images/kderra_img/realisations/Cuves/Cuve de 3000L.png",
    image2: "images/kderra_img/realisations/Cuves/Cuve1 3000L.png",
  },
  {
    title: "🌟 Augé cornadis 🌟",
    description:
      "Le cornadis Augé est le fruit de plusieurs années d’expertise et d’innovation dans le domaine des équipements pour élevage. Conçu pour répondre aux exigences les plus élevées en matière de robustesse et de fonctionnalité, cet équipement offre une solution idéale pour optimiser le confort et la sécurité de vos animaux.",
    image1: "images/kderra_img/realisations/Groupe3/Groupe7 1.png",
    image2: "images/kderra_img/realisations/Groupe3/Groupe7 2.png",
  },
  {
    title: "🐄🌱 Auge Zero Pâturage ",
    description:
      "Conçu pour les éleveurs soucieux d'optimiser la gestion de leur pâturage tout en respectant les normes environnementales, l'Auge Zero Pâturage allie innovation, durabilité et efficacité. Avec une conception réfléchie pour minimiser le gaspillage alimentaire et maximiser le confort de vos animaux, cet équipement devient rapidement un indispensable pour tout élevage moderne.",
    image1: "images/kderra_img/realisations/auge_zero_paturage.jpg",
    image2: "images/kderra_img/realisations/auge_zero_paturage1.jpg",
  },
];

export const carousel_images = [
  {
    image: "images/kderra_img/realisations/Groupe5/Groupe5.png",
    alt: "Image 1",
  },
  {
    image: "images/kderra_img/realisations/Groupe4/Groupe12.png",
    alt: "Image 2",
  },
  {
    image: "images/kderra_img/realisations/Groupe3/Groupe7 2.png",
    alt: "🌟 Augé cornadis 🌟",
  },
  {
    image: "images/kderra_img/realisations/Groupe5/Groupe5.png",
    alt: "Image 4",
  },
  {
    image: "images/kderra_img/realisations/Groupe3/Groupe7 1.png",
    alt: "🌟 Augé cornadis 🌟",
  },
  {
    image: "images/kderra_img/realisations/Groupe3/Groupe7 2.png",
    alt: "🌟 Augé cornadis 🌟",
  },
  {
    image: "images/kderra_img/realisations/Groupe3/Groupe 71.png",
    alt: "🌟 Augé cornadis 🌟",
  },
  {
    image: "images/kderra_img/realisations/Groupe2/Groupe6.png",
    alt: "Rabot standard",
  },
  {
    image: "images/kderra_img/realisations/Groupe2/Groupe6 1.png",
    alt: "Rabot standard",
  },
  {
    image: "images/kderra_img/realisations/Charue/Charrue2.png",
    alt: "🌾 Charrue Grégoire",
  },
  {
    image: "images/kderra_img/realisations/Groupe1/Groupe1.png",
    alt: "Image 11",
  },
  {
    image: "images/kderra_img/realisations/Groupe1/Groupe1 3.png",
    alt: "Image 12",
  },
  {
    image: "images/kderra_img/realisations/rabot1.jpg",
    alt: "🚜 Rabot",
  },
  {
    image: "images/kderra_img/realisations/rabot.jpg",
    alt: "🚜 Rabot",
  },
  {
    image: "images/kderra_img/realisations/poteaux.jpg",
    alt: "Poteaux",
  },
 
  {
    image: "images/kderra_img/realisations/pattin_caoutchou.jpg",
    alt: "Pattins et caoutchou",
  },
  {
    image: "images/kderra_img/realisations/grue.jpg",
    alt: "Grue",
  },
];
