import React from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import { toast } from "./ui/use-toast";

const formSchema = z.object({
  name: z.string().min(2, { message: "Le nom doit contenir au moins 2 caractères" }),
  email: z.string().email({ message: "Adresse email invalide" }),
  phone: z.string().regex(/^[0-9]{10}$/, { message: "Numéro de téléphone invalide" }),
  subject: z.string().min(1, { message: "Veuillez sélectionner un sujet" }),
  message: z.string().min(10, { message: "Le message doit contenir au moins 10 caractères" }),
});

export default function ContactSection() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = (data) => {
    const recipient = 'harouna.baguian7231@gmail.com';
    const subject = encodeURIComponent(`Nouveau message de ${data.name} - ${data.subject}`);
    const body = encodeURIComponent(`
Nom: ${data.name}
Email: ${data.email}
Téléphone: ${data.phone}
Sujet: ${data.subject}

Message:
${data.message}
    `);

    window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;

    toast({
      title: "Formulaire prêt à être envoyé",
      description: "Votre client de messagerie s'ouvrira avec les informations pré-remplies.",
    });
  };

  return (
    <section className="w-full py-16 bg-primary text-primary-foreground">
      <div className="container px-4 md:px-6 mx-auto">
        <motion.div 
          className="space-y-4 text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="inline-block rounded-lg bg-muted-primary-foreground px-3 py-1 text-sm font-semibold text-primary">
            Contactez-nous
          </div>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
            Prenez Rendez-vous ou Demandez un Devis
          </h2>
          <p className="max-w-[900px] mx-auto text-muted-primary-foreground text-lg md:text-xl">
            Remplissez le formulaire ci-dessous pour prendre rendez-vous ou demander un devis personnalisé. 
            Un de nos experts vous contactera rapidement.
          </p>
        </motion.div>
        <motion.div 
          className="mx-auto w-full max-w-lg space-y-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="grid gap-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Nom</Label>
                <Input id="name" {...register('name')} placeholder="Votre nom" />
                {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input id="email" type="email" {...register('email')} placeholder="Votre email" />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">Téléphone</Label>
              <Input id="phone" type="tel" {...register('phone')} placeholder="Votre numéro de téléphone" />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="subject">Sujet</Label>
              <select
                id="subject"
                {...register('subject')}
                className="w-full px-3 py-2 border rounded-md text-gray-700"
              >
                <option value="">Sélectionnez un sujet</option>
                <option value="devis">Demande de devis</option>
                <option value="rendez-vous">Prise de rendez-vous</option>
                <option value="information">Demande d'information</option>
              </select>
              {errors.subject && <p className="text-red-500 text-sm">{errors.subject.message}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="message">Message</Label>
              <Textarea id="message" {...register('message')} placeholder="Votre message" className="min-h-[100px]" />
              {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
            </div>
            <Button type="submit" className="w-full">
              Envoyer
            </Button>
          </form>
        </motion.div>
      </div>
    </section>
  );
}