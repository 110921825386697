const applyCookiePreferences = () => {
    const cookiePreferences = JSON.parse(localStorage.getItem('cookieConsent')) || {
      necessary: true,
      analytics: false,
      marketing: false
    };
  
    // Cookies nécessaires (toujours activés)
    document.cookie = "necessary_cookie=true; max-age=31536000; path=/";
  
    // Cookies d'analyse
    if (cookiePreferences.analytics) {
      enableAnalyticsCookies();
    } else {
      disableAnalyticsCookies();
    }
  
    // Cookies de marketing
    if (cookiePreferences.marketing) {
      enableMarketingCookies();
    } else {
      disableMarketingCookies();
    }
  };
  
  const enableAnalyticsCookies = () => {
    window.gaEnabled = true;
    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    } else {
      console.log('Google Analytics n\'est pas chargé. Assurez-vous d\'avoir ajouté le script Google Analytics à votre index.html');
    }
    console.log('Cookies d\'analyse activés');
  };
  
  const disableAnalyticsCookies = () => {
    window.gaEnabled = false;
    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    } else {
      console.log('Google Analytics n\'est pas chargé. Assurez-vous d\'avoir ajouté le script Google Analytics à votre index.html');
    }
    // Suppression des cookies existants
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    console.log('Cookies d\'analyse désactivés');
  };
  
  const enableMarketingCookies = () => {
    if (typeof window.fbq === 'function') {
      window.fbq('consent', 'grant');
    } else {
      console.log('Facebook Pixel n\'est pas chargé. Assurez-vous d\'avoir ajouté le script Facebook Pixel à votre index.html');
    }
    console.log('Cookies de marketing activés');
  };
  
  const disableMarketingCookies = () => {
    if (typeof window.fbq === 'function') {
      window.fbq('consent', 'revoke');
    } else {
      console.log('Facebook Pixel n\'est pas chargé. Assurez-vous d\'avoir ajouté le script Facebook Pixel à votre index.html');
    }
    // Suppression des cookies existants
    document.cookie = "_fbp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    console.log('Cookies de marketing désactivés');
  };
  
  export { applyCookiePreferences };