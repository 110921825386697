import React from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
    return (
        <main>
        <div className="max-w-screen-xl mx-auto px-4 flex items-center justify-start h-screen md:px-8">
            <div className="max-w-lg mx-auto space-y-3 text-center">
                <h3 className="text-kderra_primary font-semibold">
                    404 Error
                </h3>
                <p className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                    Page non trouvée
                </p>
                <p className="text-gray-600">
                    Désolé, la page que vous recherchez est introuvable ou a été supprimée.
                </p>
                <div className="flex flex-wrap items-center justify-center gap-3">
                    <Link to={"/"} className="block py-2 px-4 text-kderra_primary font-medium bg-kderra_secondary rounded-lg">
                        Retourner
                    </Link>
                   
                    <a href="javascript:void(0)" className="block py-2 px-4 text-gray-700 hover:bg-gray-50 font-medium duration-150 active:bg-gray-100 border rounded-lg">
                    Contactez le support
                    </a>
                </div>
            </div>
        </div>
    </main>
    )
}