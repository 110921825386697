
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import './Styles/App.css'
import Home from "./Routes/Home.jsx"
import Services from "./Routes/Services";
import About from "./Routes/About.jsx"
import ContactUs from "./Routes/ContactUs.jsx"
import Realizations from "./Routes/Realizations.jsx"
import { Container } from "react-bootstrap";
import ErrorPage from "./components/ErrorPage";
import SmoothScrollToTop from "./components/CustomScrollToTop";
import { AnimatePresence } from 'framer-motion';
import KderraNavbar from "components/kderra-navbar";
import CookieConsent from "components/cookie-consent";
import { Analytics } from "@vercel/analytics/react"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement:<ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/services",
        element: <Services />
      },
      {
        path: "/realisations",
        element: <Realizations />
      },
      {
        path: "/appropos",
        element: <About />
      },
      {
        path: "/contact",
        element: <ContactUs />
      },

    ]
  },
  
  
]);

function Root(){
  return (
    <div className="">
      <Container fluid>
        <KderraNavbar/>
      </Container>

      <Container fluid>
        <Outlet/>
      </Container>

      <Container fluid>
      
         <AnimatePresence>
            <SmoothScrollToTop />
          </AnimatePresence>
          <CookieConsent/>
          <Analytics />
      </Container>

    </div>
    
    
  )
}

function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
