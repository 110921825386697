import React from "react";
import { Container } from "react-bootstrap";
import Realisation2 from "../components/Realisation2"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FooterKderra from "components/FooterKderra";



function Realizations() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Kderra - Nos Réalisations</title>
        <meta
          name="description"
          content="Découvrez les projets réalisés par notre entreprise. Nous offrons des solutions sur-mesure pour divers clients. Explorez notre galerie de réalisations."
        />
        <meta
          name="keywords"
          content="CUVE SUR MESURE, Charrue Grégoire, Augé cornadis, réalisations"
        />
        <meta name="author" content="KderraSoudure" />
      </Helmet>
      <div className="Project">
        <Container fluid>
          <Realisation2 />
        </Container>
        <FooterKderra/>
      </div>
  </HelmetProvider>
    
  );
}

export default Realizations;
