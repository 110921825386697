import * as React from "react"

import { Card, CardContent } from "./ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,

} from "./ui/carousel"
import { Tools_items } from "./data/Data"
import { cn } from "../lib/utils"
import Autoplay from "embla-carousel-autoplay"

export default function CarouselFeatured() {
  const plugin = React.useRef(
    Autoplay({ delay: 3000, stopOnInteraction: false })
  )
  return (
    <Carousel
      plugins={[plugin.current]}
      opts={{
        align: "start",
      }}
      className="my-10 mx-8 "
    >
      <CarouselContent>
        {Tools_items.map((items, index) => (
          <CarouselItem key={index} className="lg:basis-1/6  basis-1/1">
            <div className="p-1">
              <Card className={cn("border")}>
                <CardContent className="flex aspect-square items-center justify-center p-3 flex-col">
                  <img src={process.env.PUBLIC_URL + items.img} alt='' className="w-[150px] h-[150px] rounded-full" />
                  <span className="text-1xl font-semibold">{items.title}</span>
                </CardContent>
              </Card>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}