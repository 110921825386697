import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { motion } from 'framer-motion';
import { Sheet, SheetTrigger, SheetContent } from "./ui/sheet";
import { Button } from "./ui/button";

export default function KderraNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const MenuItems = [
    { title: "Accueil", url: "/", id: "accueil" },
    { title: "Services", url: "/services", id: "services" },
    { title: "Réalisations", url: "/realisations", id: "realisation" },
    { title: "À propos", url: "/appropos", id: "appropos" },
  ];

  return (
    <motion.header 
      className={`fixed top-0 left-0 right-0 z-[5000] transition-all duration-500 ${
        isScrolled ? 'navbar-scrolled' : 'navbar-top'
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20 sm:h-24">
          <Link to="/" className="flex items-center space-x-3">
            <img 
              src={process.env.PUBLIC_URL + '/images/assets/logo.png'}
              alt="KderraSoudure Logo"
              className="h-12 w-auto transition-all duration-300 hover:scale-105" 
            />
          </Link>
          
          <nav className="hidden md:flex items-center space-x-8">
            {MenuItems.map((item) => (
              <NavLink key={item.id} href={item.url} name={item.title} isActive={location.pathname === item.url} />
            ))}
            <Button asChild className="nav-button">
              <Link to="/contact">Contacter</Link>
            </Button>
          </nav>
          
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="md:hidden">
                <MenuIcon className="h-8 w-8 text-gray-900" />
                <span className="sr-only">Ouvrir le menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-[300px] sm:w-[400px] mobile-menu">
              <nav className="flex flex-col space-y-6 mt-10">
                {MenuItems.map((item) => (
                  <NavLink key={item.id} href={item.url} name={item.title} mobile isActive={location.pathname === item.url} />
                ))}
                <Button asChild className="nav-button w-[50%]">
                  <Link to="/contact">Contacter</Link>
                </Button>
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </motion.header>
  );
}

const NavLink = ({ href, name, mobile, isActive }) => (
  <Link 
    to={href}
    className={`text-xl font-bold transition-all duration-300 relative ${
      mobile ? 'text-xl py-3' : 
      isActive ? 'text-primary font-semibold' : 'text-gray-700 hover:text-primary'
    }`}
  >
    {name}
    {isActive && !mobile && (
      <motion.span
        className="absolute bottom-0 left-0 w-full h-1 bg-primary rounded-sm"
        layoutId="navbar-active-indicator"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "spring", stiffness: 380, damping: 30 }}
      />
    )}
  </Link>
);

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}