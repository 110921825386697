import React from "react";
import { Container } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Home2 from "components/home-2";
import FooterKderra from "components/FooterKderra";

function Home() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Kderra - Expert en Soudure, Découpe et Pliage</title>
        <meta
          name="description"
          content="Découvrez Kderra, entreprise spécialisée dans la soudure, la découpe, et le pliage. Nous proposons des services sur-mesure pour répondre à vos besoins industriels."
        />
        <meta
          name="keywords"
          content="soudure, pliage, découpe, services, Kderra, chaudronnerie, industrie"
        />
        <meta name="author" content="KderraSoudure" />
        {/* Open Graph Metadata for Social Sharing */}
        <meta
          property="og:title"
          content="Kderra - Expert en Soudure, Découpe et Pliage"
        />
        <meta
          property="og:description"
          content="Kderra est votre partenaire pour tous vos besoins en soudure, découpe, et pliage. Découvrez nos réalisations et services."
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Metadata */}
        <meta
          name="twitter:title"
          content="Kderra - Expert en Soudure, Découpe et Pliage"
        />
        <meta
          name="twitter:description"
          content="Découvrez Kderra, entreprise spécialisée dans la soudure, la découpe, et le pliage. Nous proposons des services sur-mesure pour répondre à vos besoins industriels."
        />
      </Helmet>

      <div className="Home">
        <Container fluid>
          <Home2 />
          <div className="footer-wrapper">
            <FooterKderra />
          </div>
        </Container>
      </div>
    </HelmetProvider>
  );
}

export default Home;
