import React from 'react';

const ProjectSection = ({ title, description, image1, image2, reverse = false }) => {
    
  return (
    <div
      className={`flex items-center flex-col-reverse
       lg:flex-row ${reverse ? 'lg:flex-row-reverse' : ''} justify-between lg:mt-[4.5rem] mt-[2.5rem] gap-2`}
    >
      <div className="text-start w-full lg:w-[45%] text-[1.1rem]">
        <h2 className="text-2xl font-bold pb-[1rem]">{title}</h2>
        <p>{description}</p>
      </div>

      <div className="relative flex lg:w-[50%] w-full justify-between z-[-99] gap-2">
        <img
          alt={title}
          src={image1}
          className="w-[49%] lg:h-[350px] h-[250px] object-cover rounded-sm shadow-md mt-10"
        />
        <img
          alt={title}
          src={image2}
          className="w-[49%] lg:h-[350px] h-[250px] object-cover rounded-sm shadow-md"
        />
      </div>
    </div>
  );
};

export default ProjectSection;
