import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from "./ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel"
import Autoplay from "embla-carousel-autoplay"

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const PartnerLogo = ({ src, alt }) => (
  <Card className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
    <CardContent className="flex items-center justify-center p-6">
      <img
        src={src}
        alt={alt}
        className="max-w-full max-h-16 object-contain"
      />
    </CardContent>
  </Card>
);

export default function PartnersSection() {
  const partners = [
    { src: "images/assets/asset 16.jpeg", alt: "Partenaire 1" },
    { src: "images/assets/asset 17.jpeg", alt: "Partenaire 2" },
    { src: "/placeholder.svg", alt: "Partenaire 3" },
    { src: "/placeholder.svg", alt: "Partenaire 4" },
    { src: "/placeholder.svg", alt: "Partenaire 5" },
  ];

  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  )

  return (
    <section className="w-full py-16 bg-gray-50">
      <div className="container px-4 md:px-6 mx-auto">
        <motion.div
          className="text-center mb-12"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <span className="inline-block bg-primary text-white text-sm font-semibold px-4 py-2 rounded-full mb-4">
            Nos Partenaires
          </span>
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-4">
            Nos Partenaires de Confiance
          </h2>
          <p className="max-w-2xl mx-auto text-xl text-gray-600">
            Nous collaborons étroitement avec des partenaires de renom pour vous offrir les meilleures solutions dans le domaine de la soudure et du pliage.
          </p>
        </motion.div>

        <motion.div
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
            plugins={[plugin.current]}
            className="w-full max-w-sm mx-auto"
          >
            <CarouselContent>
              {partners.map((partner, index) => (
                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                  <div className="p-1">
                    <PartnerLogo src={partner.src} alt={partner.alt} />
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </motion.div>
      </div>
    </section>
  );
}