import React from 'react';
import { motion } from 'framer-motion';

const FooterSection = ({ title, children, className }) => (
  <motion.div
    className={`mb-8 md:mb-0 ${className}`}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="text-kderra_primary text-xl font-bold mb-4 uppercase text-center">{title}</h2>
    {children}
  </motion.div>
);

const FooterLink = ({ href, children, className }) => (
  <a
    href={href}
    className={`hover:text-kderra_primary transition-colors duration-300 ${className}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

const SocialLink = ({ href, icon, label }) => (
  <FooterLink href={href} className="flex items-center justify-center w-full py-2">
    <img src={icon} alt={label} className="w-6 h-6 mr-2" />
    <span>{label}</span>
  </FooterLink>
);

const FooterKderra = () => {
  return (
    <footer className="bg-primary text-gray-300 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <FooterSection title="Mes Services">
            <ul className="space-y-2">
              <li><FooterLink href="#">Soudure</FooterLink></li>
              <li><FooterLink href="#">Découpe</FooterLink></li>
              <li><FooterLink href="#">Pliage</FooterLink></li>
            </ul>
          </FooterSection>

          <FooterSection title="Mes Activités">
            <ul className="space-y-2">
              <li><FooterLink href="#">Réparation Agricoles</FooterLink></li>
              <li><FooterLink href="#">Réparation Industrielle</FooterLink></li>
              <li><FooterLink href="#">Réparation Mécanique</FooterLink></li>
              <li><FooterLink href="#">Contact</FooterLink></li>
            </ul>
          </FooterSection>

          <FooterSection title="Nos Réseaux">
            <div className="flex flex-col items-center space-y-4">
              <SocialLink 
                href="https://www.facebook.com/K.derra18?checkpoint_src=any"
                icon={process.env.PUBLIC_URL + "/images/assets/asset 19.svg"}
                label="Facebook"
              />
              <SocialLink 
                href="https://x.com/i/flow/login?redirect_after_login=%2Fkderra26"
                icon={process.env.PUBLIC_URL + "/images/assets/asset 20.svg"}
                label="Twitter"
              />
            </div>
          </FooterSection>

          <FooterSection title="Contact">
            <div className="flex flex-col items-center">
              <img src={process.env.PUBLIC_URL + '/images/assets/asset 18.svg'} alt="Logo" className="w-32 h-auto mb-4" />
              <p className="text-kderra_primary text-xl font-bold">ABDOULAYE K.DERRA</p>
              <p>SAINT FIACRE - 29820 GUILERS</p>
              <p className="text-2xl text-kderra_primary font-bold my-2">07 51 58 08 35</p>
              <p>KDERRA26@HOTMAIL.COM</p>
            </div>
          </FooterSection>
        </div>

        <div className="border-t border-gray-700 mt-12 pt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Copyright KDERRA - Une création de ToemeXpertise</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterKderra;