import React, { useState, useEffect } from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './ui/alert-dialog';
import { Checkbox } from './ui/checkbox';
import { applyCookiePreferences } from './cookie-utils';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    } else {
      setCookiePreferences(JSON.parse(consent));
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
    setShowConsent(false);
    applyCookiePreferences();
  };

  const handleReject = () => {
    const rejectedPreferences = { necessary: true, analytics: false, marketing: false };
    localStorage.setItem('cookieConsent', JSON.stringify(rejectedPreferences));
    setCookiePreferences(rejectedPreferences);
    setShowConsent(false);
    applyCookiePreferences();
  };

  const handlePreferenceChange = (cookieType) => {
    setCookiePreferences(prev => ({
      ...prev,
      [cookieType]: !prev[cookieType]
    }));
  };

//   const applyCookiePreferences = () => {
//     // Appliquer les préférences de cookies ici
//     if (cookiePreferences.analytics) {
//       // Activer les cookies d'analyse
//       console.log('Cookies d\'analyse activés');
//     } else {
//       // Désactiver les cookies d'analyse
//       console.log('Cookies d\'analyse désactivés');
//     }

//     if (cookiePreferences.marketing) {
//       // Activer les cookies de marketing
//       console.log('Cookies de marketing activés');
//     } else {
//       // Désactiver les cookies de marketing
//       console.log('Cookies de marketing désactivés');
//     }
//   };

  return (
    <AlertDialog open={showConsent}>
      <AlertDialogContent className="max-w-3xl">
        <AlertDialogHeader>
          <AlertDialogTitle>Paramètres des cookies</AlertDialogTitle>
          <AlertDialogDescription>
            Nous utilisons des cookies pour améliorer votre expérience sur notre site. 
            Veuillez choisir vos préférences ci-dessous.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="my-4 space-y-4">
          <div className="flex items-center space-x-2">
            <Checkbox id="necessary" checked disabled />
            <label htmlFor="necessary" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Cookies nécessaires (toujours actifs)
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox 
              id="analytics" 
              checked={cookiePreferences.analytics}
              onCheckedChange={() => handlePreferenceChange('analytics')}
            />
            <label htmlFor="analytics" className="text-sm font-medium leading-none">
              Cookies d'analyse
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox 
              id="marketing" 
              checked={cookiePreferences.marketing}
              onCheckedChange={() => handlePreferenceChange('marketing')}
            />
            <label htmlFor="marketing" className="text-sm font-medium leading-none">
              Cookies de marketing
            </label>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleReject}>Refuser tout</AlertDialogCancel>
          <AlertDialogAction onClick={handleAccept}>Accepter la sélection</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CookieConsent;