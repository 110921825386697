import React, { useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FooterKderra from 'components/FooterKderra';




// light theme style
const lightTheme = {
  background: '#f5f5f5',
  text: '#333333',
  accent: '#d4da2e', // old chocolate
  primary: '#3c3c3c', // old #007bff
  secondary: '#6c757d',
  cardBg: '#ffffff',
  cardBorder: '#e0e0e0',
  cardHoverBorder: '#007bff',
  iconBg: '#f0f0f0',
  buttonBg: '#3c3c3c', // old #007bff
  buttonHoverBg: '#3c3c3c', // old #0056b3
};


 

// Page Body style
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: background 0.3s, color 0.3s;
  }
`;


// section style
const Section = styled.div`
  padding-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

 
// section title style
const SectionTitle = styled.p`
font-family: 'Playfair Display', serif;
font-size: 1.8rem;
line-height: 1.6;
color: ${({ theme }) => theme.text};
max-width: 800px;
margin: 40px auto;
padding: 0 20px;
position: relative;
font-weight: bolder;

&::first-letter {
  initial-letter: 3;
  -webkit-initial-letter: 3;
  color: ${({ theme }) => theme.accent};
  font-weight: 700;
  margin-right: 0.2em;
}

&::before, &::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, transparent, ${({ theme }) => theme.accent}, transparent);
}

&::before { top: -20px; }
&::after { bottom: -20px; }

@media (max-width: 768px) {
  font-size: 1.4rem;
}

a {
  color: ${({ theme }) => theme.accent};
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.accent};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.accentHover};
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}
`;
 



// Grid to display card style
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
`;


// card style
const Card = styled(motion.div)`
  background: ${({ theme }) => theme.cardBg};
  border: 1px solid ${({ theme }) => theme.cardBorder};
  border-radius: 15px;
  padding: 30px;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: ${({ theme }) => theme.cardHoverBorder};
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;



// card header style
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;


// card title style
const CardTitle = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.primary};
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 2px 2px 4px #3c3c3c
  font-weight: bolder;
`;

// card content style
const CardContent = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  line-height: 1.5;
`;

// subheading for services style 
const SubHeading = styled.h3`
  font-size: 1.2rem;
 color: #d4da2e; // old chocolate
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;


// List to display materiaux or thechnics style
const List = styled.ul`
  list-style: none;
  padding: 0;
`;

// ListeItem to display materiaux or thechnics style
const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;


// Services Avatar Container style
const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;


// materiaux or thechniques avatar style
const AvatarIcon = styled.div`
  background: ${({ theme }) => theme.iconBg};
  color: ${({ theme }) => theme.secondary};
  font-size: 1.5rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
`;


// tool image style
const ToolImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;


// call to action Button Style
const CallToAction = styled.a`
  background-color: ${({ theme }) => theme.buttonBg};
  color: #d4da2e; // old #fff
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBg};
    transform: translateY(-2px);
  }
`;


// services data
const services = {
    Soudure: {
      icon: './images/kderra_img/services/s_soudure.jpg',   
      description: "Soudure de haute qualité pour l'acier, l'inox et l'aluminium.",
      materials: ['Acier', 'Inox', 'Aluminium'],
      projects: ['Réparation de structures en acier', 'Fabrication de pièces en inox sur mesure'],
    },
    Découpe: {
      icon: './images/kderra_img/services/s_decoupe.jpg',  
      description: 'Techniques de découpe avancées pour des résultats précis et nets.',
      techniques: ['Découpe laser', 'Découpe plasma', 'Découpe jet d\'eau'],
      projects: ['Découpe de pièces pour l\'industrie automobile', 'Création de panneaux décoratifs'],
    },
    Pliage: {
      icon: './images/kderra_img/services/s_pliage.jpg',  
      description: 'Pliage professionnel avec finition parfaite de vos pièces en métal.',
      materials: ['Acier', 'Inox', 'Aluminium'],
      projects: ['Pliage de tôles pour la construction navale', 'Fabrication de boîtiers métalliques'],
    },
};


// tools data 
const tools = [
  { name: 'Guillotine 3 x 10', image: './images/kderra_img/services/tool_guilontine.jpg' },
  { name: 'Fraiseuse conventionnelle', image: './images/kderra_img/services/tool_fraiseurse_conventionnelle.jpg' },
  { name: 'Poste à souder semi automatique', image: './images/kderra_img/services/tool_poste-a-souder-semi-automatique.jpg' },
  { name: 'Rouleuse', image: './images/kderra_img/services/tool_rouleuse.jpg' },
  { name: 'Poinçonneuse', image: './images/kderra_img/services/tool_poinceuse.jpg' },
  { name: 'Presse plieuse', image: './images/kderra_img/services/tool_presse_plieuse.jpg' },
  { name: 'Cisaille poissonneuse', image: './images/kderra_img/services/tool_poinconneuse-cisaille.jpg' },
  { name: 'Scie à ruban automatique et manuel', image: './images/kderra_img/services/tool_scie_ruban.jpg' },
  { name: 'Perceuse à colonne', image: './images/kderra_img/services/tool_perceuse-a-colonne.jpg' },
];

 
 





// principal function
function Services() {
    
    const auto_scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    useEffect(() => {
      auto_scrollToTop();
    }, []);
    
  
  return (
    <HelmetProvider>
      <ThemeProvider theme={lightTheme}>
       
      {/* Helmet for SEO */}
      <Helmet>
        <title>Kderra Services</title>
        <meta name="description" content="Bienvenue dans notre catalogue de services." />
        <meta name="keywords" content="soudure, pliage, découpe ,services, catalogue, Kderra Soudure, entreprise" />
        <meta name="author" content="KderraSoudure" />
      </Helmet>

        {/*  display style for body */}
        <GlobalStyle />

        {/*  CREATE SECTION FOR  services and tools */}
        <Section>
          {/* Title for Services part */}
          <SectionTitle
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Nos Services
          </SectionTitle>

          {/* Grid container to display services*/}
          <Grid>
            {Object.entries(services).map(([name, service]) => (
              <Card
                key={name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <CardHeader>
                  <IconWrapper>
                    <img src={service.icon} alt={name} style={{ width: '100%', height: '100%' }} />
                  </IconWrapper>
                  <CardTitle>{name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>{service.description}</p>
                  {service.techniques && (
                    <>
                      <SubHeading>Techniques utilisées :</SubHeading>
                      <List>
                        {service.techniques.map((technique) => (
                          <ListItem key={technique}>
                            <AvatarIcon>🔧</AvatarIcon>
                            {technique}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  {service.materials && (
                    <>
                      <SubHeading>Matériaux utilisés :</SubHeading>
                      <List>
                        {service.materials.map((material) => (
                          <ListItem key={material}>
                            <AvatarIcon>🛠️</AvatarIcon>
                            {material}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  {service.projects && (
                    <>
                      <SubHeading>Projets réalisés :</SubHeading>
                      <List>
                        {service.projects.map((project) => (
                          <ListItem key={project}>
                            <AvatarIcon>🏆</AvatarIcon>
                            {project}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </CardContent>
                <CallToAction href='/contact' className='text-[#d4da2e]'>Contacter</CallToAction>
              </Card>
            ))}
          </Grid>


          {/* Title for Tools part */}
          <SectionTitle
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Nos Outils
          </SectionTitle>

          {/* Grid container to display Tools */}
          <Grid>
            {tools.map((tool) => (
              <Card
                key={tool.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <ToolImage src={tool.image} alt={tool.name} />
                <CardTitle>{tool.name}</CardTitle>
              </Card>
            ))}
          </Grid>
        </Section>

        <div className="footer-wrapper">
        <FooterKderra/>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default Services;
