
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import { Icon } from 'leaflet';

const LeafletMap = () => {
  const position = [48.4322, -4.5678]; // Coordonnées de "K.derra soudure, 29820 Guilers, France"
  const customIcon = new Icon(
    {
      iconUrl: process.env.PUBLIC_URL + "/images/location.png",
      iconSize: [38, 50],
    }
  )

  return (
    <MapContainer center={position} zoom={10} scrollWheelZoom={false} 
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          K.derra soudure<br /> 29820 Guilers, France
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LeafletMap;
