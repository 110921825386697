import React, { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Button } from "./ui/button";
import { Separator } from "./ui/separator";
import { IoMdSend } from "react-icons/io";

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('Demande de Devis');
  const [message, setMessage] = useState('');

  const contactMethods = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
      ),
      contact: "KDERRA26@HOTMAIL.COM",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
      ),
      contact: "+33 7 51 58 08 35",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent(`Contact de ${name} - ${reason}`);
    const body = encodeURIComponent(`Nom: ${name}\nEmail: ${email}\nMotif: ${reason}\n\nMessage:\n${message}`);
    window.location.href = `mailto:baguian.harouna7231@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <main className="py-14 mt-20">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="max-w-lg gap-8 justify-between lg:flex lg:max-w-none">
          <div className="max-w-lg ml-0 text-left space-y-3">
            <p className="text-gray-800 text-xl font-semibold sm:text-3xl">
              KderraSoudure
            </p>
            <p>
              Nous vous accompagons dans la réalisation de vos projets,
              contactez nous.
            </p>
            <div>
              <ul className="mt-6 ml-10 flex flex-wrap gap-x-10 gap-y-6 lg:ml-8">
                {contactMethods.map((item, idx) => (
                  <li key={idx} className="flex items-start gap-x-3 -ml-8">
                    <div className="flex-none text-gray-400">{item.icon}</div>
                    <p>{item.contact}</p>
                  </li>
                ))}
              </ul>
            </div>
            <Separator orientation="horizontal" />
            <div className="gap-2 flex flex-col">
              <div className="w-full flex flex-col sm:flex-row gap-2 justify-between my-4">
                <a
                  href="https://x.com/i/flow/login?redirect_after_login=%2Fkderra26"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/assets/asset 20.svg"}
                    alt="Twitter"
                    className="w-8 h-8 mr-2"
                  />
                  <span>TWITTER</span>
                </a>
                <a
                  href="https://www.facebook.com/K.derra18?checkpoint_src=any"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mb-2"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/assets/asset 19.svg"}
                    alt="Facebook"
                    className="w-8 h-8 mr-2"
                  />
                  <span>FACEBOOK</span>
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label className="font-medium">Nom | Prénom</label>
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                />
              </div>
              <div>
                <label className="font-medium">Email</label>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                />
              </div>
              <div>
                <label className="font-medium">Motif</label>
                <Select 
                  value={reason} 
                  onValueChange={setReason}
                >
                  <SelectTrigger className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg">
                    <SelectValue placeholder="Sélectionnez un motif" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Demande de Devis">Demande de Devis</SelectItem>
                    <SelectItem value="Demande d'informations">Demande d'informations</SelectItem>
                    <SelectItem value="Autres">Autres</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="font-medium">Message</label>
                <textarea
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                ></textarea>
              </div>
              <Button type="submit" className="w-full px-4 py-2 gap-4 text-kderra_secondary font-bold bg-kderra_primary hover:bg-kderra_primary active:bg-kderra_primary rounded-lg duration-150">
                <IoMdSend />
                Envoyer
              </Button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}