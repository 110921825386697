import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import AtomeHero from "../components/AtomeHero";
import CarouselFeatured from "../components/tools";
import FooterKderra from "components/FooterKderra";

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};
function About() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          À propos de KderraSoudure - Expert en soudure, pliage et découpe
        </title>
        <meta
          name="description"
          content="Découvrez KderraSoudure, votre expert en soudure, pliage et découpe à Plabennec. Spécialisés dans la réparation agricole, industrielle et la restauration mécanique."
        />
        <meta
          name="keywords"
          content="soudure, pliage, découpe, réparation agricole, réparation industrielle, restauration mécanique, Plabennec, Finistère"
        />
        <meta name="author" content="KderraSoudure" />
      </Helmet>

      <div className="About py-12 lg:py-32">
        <div className="relative overflow-hidden bg-gradient-to-br from-gray-100 to-gray-200 py-20 lg:py-32">
          <motion.div
            className="absolute top-0 left-0 w-full h-full opacity-10"
            initial={{ rotate: 0, scale: 1 }}
            animate={{ rotate: 360, scale: 1.5 }}
            transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
          >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#D4DA2E"
                d="M44.7,-76.4C58.8,-69.2,71.8,-59.1,79.6,-45.8C87.4,-32.5,90,-16.3,89.3,-0.4C88.7,15.5,84.8,31,76.9,44.2C69,57.4,57.1,68.3,43.3,75.7C29.5,83.1,14.8,87,0.1,86.8C-14.6,86.6,-29.2,82.4,-42.8,75.1C-56.4,67.8,-69,57.4,-77.1,44.1C-85.2,30.8,-88.9,15.4,-88.3,0.3C-87.8,-14.8,-83,-29.5,-74.9,-42.1C-66.8,-54.7,-55.4,-65,-42.3,-71.9C-29.2,-78.7,-14.6,-82.1,0.8,-83.4C16.2,-84.7,32.4,-83.9,44.7,-76.4Z"
                transform="translate(100 100)"
              />
            </svg>
          </motion.div>

          <div className="container mx-auto px-4 relative z-10">
            <motion.h1
              className="text-5xl lg:text-7xl font-bold text-center mb-12 text-primary"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              KderraSoudure
              <span className="block text-3xl lg:text-4xl text-gray-600 mt-2">
                L'Excellence en Soudure et Métallurgie
              </span>
            </motion.h1>

            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <motion.div
                className="space-y-6 text-lg text-gray-700"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
              >
                <p className="leading-relaxed">
                  Basée à Plabennec dans le Finistère (29), KderraSoudure est
                  votre partenaire de confiance pour tous vos projets de
                  soudure, pliage et découpe. Avec plus de deux décennies
                  d'expérience, nous nous sommes forgé une réputation
                  d'excellence et de fiabilité dans le domaine de la
                  métallurgie.
                </p>
                <p className="font-semibold text-xl text-primary">
                  Notre mission : Fournir des solutions sur-mesure de haute
                  qualité pour répondre à vos besoins spécifiques, qu'il
                  s'agisse de réparation, de fabrication ou de restauration.
                </p>
              </motion.div>

              <motion.div
                className="rounded-2xl overflow-hidden shadow-2xl transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
              >
                <AtomeHero
                  cName="hero-mid"
                  heroImg="/images/kderra_img/machine_decoupe.jpg"
                  title="Notre Expertise"
                  multiText={[
                    "Réparation agricole",
                    "Réparation industrielle",
                    "Soudure de précision",
                    "Pliage sur-mesure",
                    "Découpe haute précision",
                    "Restauration mécanique",
                    "Chaudronnerie",
                    "Fabrication de structures acier et inox",
                    "Soudure aluminium",
                  ]}
                  btnClass="hide"
                />
              </motion.div>
            </div>
          </div>
        </div>

        <Container fluid className="py-16">
          <motion.div
            className="container mx-auto px-4"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <div className="text-center w-[60%] m-auto">
              <h1 className="text-[#2d3954] font-semibold capitalize text-[35px]">
                Nos Services
              </h1>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mt-8">
              {[
                {
                  title: "Soudure de Précision",
                  icon: "🔧",
                  description:
                    "Expertise en soudure acier, inox et aluminium pour des assemblages parfaits.",
                },
                {
                  title: "Pliage Sur-Mesure",
                  icon: "📐",
                  description:
                    "Réalisation de pliages complexes pour donner forme à vos projets.",
                },
                {
                  title: "Découpe Haute Précision",
                  icon: "✂️",
                  description:
                    "Découpe précise de vos pièces grâce à nos équipements de pointe.",
                },
                {
                  title: "Réparation Agricole",
                  icon: "🚜",
                  description:
                    "Maintenance et réparation de vos équipements agricoles pour une performance optimale.",
                },
                {
                  title: "Réparation Industrielle",
                  icon: "🏭",
                  description:
                    "Solutions rapides et efficaces pour vos machines industrielles.",
                },
                {
                  title: "Restauration Mécanique",
                  icon: "🔩",
                  description:
                    "Redonnez vie à vos pièces mécaniques grâce à notre expertise en restauration.",
                },
              ].map((service, index) => (
                <motion.div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="text-4xl mb-4">{service.icon}</div>
                  <h3 className="text-xl font-semibold mb-2">
                    {service.title}
                  </h3>
                  <p>{service.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </Container>

        <Container fluid className="bg-gray-100 py-16">
          <motion.div
            className="container mx-auto px-4"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <div className="text-center w-[60%] m-auto">
              <h1 className="text-[#2d3954] font-semibold capitalize text-[35px]">
                Notre Équipement
              </h1>
            </div>

            <CarouselFeatured />
          </motion.div>
        </Container>

        <Container fluid className="py-16">
          <motion.div
            className="container mx-auto px-4 text-center"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-3xl font-bold mb-8">
              Pourquoi Choisir KderraSoudure ?
            </h2>
            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  title: "Expertise",
                  description: "Plus de 20 ans d'expérience dans le métier",
                },
                {
                  title: "Qualité",
                  description: "Travail de précision et finitions soignées",
                },
                {
                  title: "Sur-Mesure",
                  description: "Solutions adaptées à vos besoins spécifiques",
                },
                {
                  title: "Fiabilité",
                  description: "Engagements respectés et délais maîtrisés",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="bg-primary text-white p-6 rounded-lg"
                >
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </motion.div>
        </Container>
        <div className="footer-wrapper">
          <FooterKderra />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default About;
