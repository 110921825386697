import React from "react";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Contact from "../components/Contact";
import LeafletMap from "../components/LeafletMap";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

function ContactUs() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Contactez KderraSoudure - Votre Expert en Soudure</title>
        <meta name="description" content="Contactez KderraSoudure pour des solutions sur-mesure en soudure, pliage et découpe. Expertise et qualité à votre service." />
        <meta name="keywords" content="contact, soudure, pliage, découpe, Kderra Soudure, Guilers" />
        <meta name="author" content="KderraSoudure" />
      </Helmet>
      
      <Parallax pages={3} className=" relative lg:py-32 py-20">
        <ParallaxLayer offset={0} speed={0.5}>
          <div className="h-screen bg-cover bg-center" style={{backgroundImage: 'url("/images/kderra_img/services/s_decoupe.jpg")'}}>
            <div className="h-full flex items-center justify-center bg-black bg-opacity-50">
              <motion.h1 
                className="text-5xl md:text-7xl text-white font-bold text-center"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
              >
                Contactez KderraSoudure
              </motion.h1>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.2}>
          <Container fluid className="py-20">
            <motion.div 
              className="max-w-4xl mx-auto bg-white bg-opacity-90 p-8 rounded-lg shadow-2xl"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <h2 className="text-3xl font-bold mb-6 text-center">Prenons Contact</h2>
              <Contact />
            </motion.div>
          </Container>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0.5} className="py-32">
          <div className="h-screen bg-cover bg-center" style={{backgroundImage: 'url("/images/kderra_img/soudure_2.jpg")'}}>
            <div className="h-full flex flex-col items-center justify-center bg-black bg-opacity-50 p-4">
              <motion.h2 
                className="text-3xl md:text-5xl text-white font-bold mb-8 text-center"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
              >
                K.derra soudure à Guilers, France
              </motion.h2>
              <div className="w-full max-w-4xl h-96 rounded-lg overflow-hidden shadow-2xl">
                <LeafletMap />
              </div>
            </div>
          </div>
        </ParallaxLayer>
      </Parallax>
    </HelmetProvider>
  );
}

export default ContactUs;