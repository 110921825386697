import React from "react";
import ProjectSection from "./ProjectSection";
import { projects_items, carousel_images } from "./data/realisations";
import { Card, CardContent } from "./ui/card";
import { Carousel, CarouselContent, CarouselItem } from "./ui/carousel";
import Autoplay from "embla-carousel-autoplay";



/* The `Realisation2` component is a functional React component that displays a list of projects and a
carousel of images.*/
const Realisation2 = () => {
  const plugin = React.useRef(
    Autoplay({ delay: 3000, stopOnInteraction: false })
  );
  return (
    <div className="mt-[100px] lg:mb-16 mb-16 lg:mx-24 mx-10">
      

      
      <div className="text-center w-[60%] m-auto">
        <h1 className="text-[#2d3954] font-semibold capitalize text-[35px]">Nos Réalisations</h1>
        <p className="text-[#72809d]">Nous réalisons des projets sur-mésures pour nos différents clients</p>
      </div>

      {projects_items.map((project, idx) => (
        <ProjectSection
          key={idx}
          title={project.title}
          description={project.description}
          image1={process.env.PUBLIC_URL + project.image1}
          image2={process.env.PUBLIC_URL + project.image2}
          reverse={idx % 2 !== 0}
        />
      ))}

      <Carousel
        plugins={[plugin.current]}
        opts={{
          align: "start",
        }}
        className="lg:my-16  my-2"
      >
        <CarouselContent>
          {carousel_images.map((items, index) => (
            <CarouselItem key={index} className="lg:basis-1/4 basis-1/2">
              <div className="p-1">
                <Card className="shadow-none border-none">
                  <CardContent className="flex aspect-square items-center justify-center p-3 flex-col group">
                    <img
                      src={process.env.PUBLIC_URL + items.image}
                      alt={items.alt}
                      className="lg:w-[600px] w-full h-[200px] lg:h-[400px] object-fit rounded-sm shadow-sm transition duration-300 ease-in-out hover:scale-110"
                    />
                    <div className=" absolute flex items-center justify-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                      <h2 className="text-white text-lg font-bold">
                        {items.alt}
                      </h2>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
export default Realisation2;
